var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "sidebar-close close",
        attrs: { type: "button", "aria-label": "Close" },
        on: {
          click: function($event) {
            return _vm.hideSidebar()
          }
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sidebar-content p-5" }, [
      _c("div", { staticClass: "h4 mb-5 text-center" }, [
        _vm._v("\n      Votre panier est vide\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _c("img", {
          attrs: {
            src: "/static/dist/images/icons/empty-cart.png",
            width: "48px"
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "p-5" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary btn-block mb-4",
            attrs: { href: "/commande/granules" }
          },
          [
            _c("img", {
              attrs: {
                src: "/static/dist/images/icons/pellet-bag.png",
                height: "32px"
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "ml-3" }, [
              _vm._v("\n          Commander des granulés en sac\n        ")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary btn-block mb-4",
            attrs: { href: "/commande/granules" }
          },
          [
            _c("img", {
              attrs: {
                src: "/static/dist/images/icons/pellet.png",
                height: "18px"
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "ml-3" }, [
              _vm._v("\n          Commander des granulés en vrac\n        ")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary btn-block",
            attrs: { href: "/commande/logs/" }
          },
          [
            _c("img", {
              attrs: {
                src: "/static/dist/images/icons/log.png",
                height: "18px"
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "ml-3" }, [
              _vm._v("\n          Commander du bois bûche\n        ")
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }