var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "d-flex align-items-center justify-content-center ml-0 ml-xl-3"
    },
    [
      _vm.auth === false
        ? _c(
            "a",
            {
              staticClass:
                "d-none align-items-center justify-content-center d-xl-none btn btn-secondary btn-user text-center",
              attrs: { href: "#modal-signin", "data-toggle": "modal" }
            },
            [
              _c("img", {
                attrs: {
                  src: "/static/dist/images/icons/user.png",
                  width: "24px"
                }
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.auth === false
        ? _c("div", { staticClass: "d-none d-xl-block dropdown" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1)
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("account-connexion", {
        on: { "connexion-success": _vm.connexion_success }
      }),
      _vm._v(" "),
      _c("account-creation", {
        on: { "creation-success": _vm.creation_success }
      }),
      _vm._v(" "),
      _vm.auth
        ? _c(
            "div",
            { staticClass: "dropdown" },
            [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "dropdown-menu dropdown-menu-right bg-secondary border-0 px-2 py-3",
                  attrs: { "aria-labelledby": "dropdown-account" }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item font-weight-bold",
                      attrs: { href: "#modal-account", "data-toggle": "modal" }
                    },
                    [_vm._v("\n        Mon compte\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item font-weight-bold",
                      class: { disabled: _vm.items.length === 0 },
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.last_order()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        Renouveler ma dernière commande\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-item font-weight-bold",
                      attrs: { href: "#", "data-toggle": "modal" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.logout()
                        }
                      }
                    },
                    [_vm._v("\n        Se déconnecter\n      ")]
                  )
                ]
              ),
              _vm._v(" "),
              _c("account", {
                attrs: { user: _vm.user },
                on: { "update-success": _vm.update_success }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary btn-cart ml-2 d-none d-xl-flex",
          on: {
            click: function($event) {
              return _vm.showSidebar()
            }
          }
        },
        [
          _c("img", {
            attrs: {
              src: "/static/dist/images/icons/white-cart.png",
              width: "24px"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("sidebar", {
        class: { show: _vm.sidebar },
        on: {
          "order-modal-closed": function($event) {
            return _vm.closeOrderAborted()
          }
        }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "overlay",
        class: { active: _vm.sidebar },
        on: {
          click: function($event) {
            return _vm.hideSidebar()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-secondary dropdown-toggle btn-user",
        attrs: {
          type: "button",
          id: "dropdown-auth",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [
        _c("img", {
          attrs: { src: "/static/dist/images/icons/user.png", width: "24px" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "dropdown-menu dropdown-menu-right bg-secondary border-0 px-2 py-3",
        attrs: { "aria-labelledby": "dropdown-auth" }
      },
      [
        _c(
          "a",
          {
            staticClass: "dropdown-item font-weight-bold",
            attrs: { href: "#modal-signin", "data-toggle": "modal" }
          },
          [_vm._v("\n        Connexion\n      ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "dropdown-item font-weight-bold",
            attrs: { href: "#modal-signup", "data-toggle": "modal" }
          },
          [_vm._v("\n        Inscription\n      ")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-secondary dropdown-toggle btn-user",
        attrs: {
          type: "button",
          id: "dropdown-account",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [
        _c("img", {
          attrs: { src: "/static/dist/images/icons/user.png", width: "24px" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }