<template src="../../templates/orderStep.html" />
<script>
import _ from 'lodash'
import Bus from '../Bus.js'
import axios from 'axios'
import autocomplete from './autocomplete.vue'
import vueScrollTo from 'vue-scrollto'

export default {
  name: "orderStep",
  props: [
    "products",
    "lastProduct",
    "categorySelected",
    "items",
    "auth",
    "orders",
    "user"
  ],
  components: {
    "auto-complete": autocomplete
  },

  created() {
    if (localStorage.getItem('customerType') === 'pro') {
      this.customerType = 'pro'
    }
    if (window.location.href.includes('?type=vrac')) {
      this.customerType = 'pro'
    }

    if (this.categorySelected == "logs") {
      var city_url = "/simple-product/rest/distance-log-list/";
    } else {
      var city_url = "/simple-product/rest/distance-granule-list/";
    }
    axios({
      method: "get",
      url: city_url
    })
      .then(response => {
        if (this.categorySelected == "logs") {
          for (var i = 0; i < response.data.length; i++) {
            for (var x = 0; x < this.logCity.length; x++) {
              if (response.data[i].zipcode == this.logCity[x]) {
                this.city.push(response.data[i]);
              }
            }
          }
        } else {
          this.city = response.data;
        }
        //console.log("citty created", this.city.length);
        if (localStorage.getItem("previousProduct")) {
          var productId = JSON.parse(localStorage.getItem("previousProduct"));
          //console.log("previous:", typeof productId);
          for (var i = 0; i < this.products.length; i++) {
            if (productId == this.products[i].id) {
              var product = this.products[i];
              //console.log("product", product.title);
              break;
            }
          }
          this.product_selected(product);
        }
        if (
          localStorage.getItem("orderAborted") &&
          localStorage.getItem("command")
        ) {
          var commandStorage = JSON.parse(localStorage.getItem("command"));
          var product = commandStorage.product;
          this.product_selected(product);
          this.packaging_selected(commandStorage.packageStorage);
          this.delivery_choosen(commandStorage.delivery.type);
          this.delivery = commandStorage.delivery.type;
          for (var i = 0; i < commandStorage.accessories.length; i++) {
            for (var x = 0; x < this.products.length; x++) {
              if (this.products[x].id == commandStorage.accessories[i].id) {
                this.products[x].selected = true;
              }
            }
          }
          for (var i = 0; i < commandStorage.options.length; i++) {
            for (var x = 0; x < this.products.length; x++) {
              if (this.products[x].id == commandStorage.options[i].id) {
                this.products[x].selected = true;
              }
            }
          }
          localStorage.removeItem("orderAborted");
        }
        this.calcul_city;
      })
      .catch(error => {});
  },
  beforeUpdate() {
    if (document.body.clientWidth < 768) {
      this.navMobile = true;
    } else {
      this.navMobile = false;
    }
    if (this.packaging !== "palett") {
      // ne permet pas de commander si option livraison choisit si le packaging n'est pas une palette
      if (this.delivery == "home") {
        //console.log("anti delivery");
        var delivery = "";
        this.delivery_choosen(delivery);
      }
    }
    Bus.$on("customer-select", type => {
      this.customerType = type;
    });
    Bus.$on("check-delivery", () => {
      this.calcul_city;
    });
    this.calcul_city;
    if (this.lastProduct) {
      this.product = this.lastProduct;
    }
  },
  data() {
    return {
      logCity: [
        "47000",
        "47240",
        "47310",
        "47390",
        "47450",
        "47480",
        "47510",
        "47520",
        "47550",
        "47130",
        "47220",
        "47270",
        "47340",
        "47360",
        "47470",
        "47600",
        "32700"
      ],
      city: [],
      navMobile: false,
      cityMessage: "",
      searchCity: "",
      customerType: "",
      product: "",
      selectedCity: {},
      checkCityDelivery: true,
      packaging: "",
      selectedCityDrive: 'agen',
      delivery_mode: "",
      selectedAccessory: "",
      selectedAccessories: [],
      allAccessories: false,
      emptyAccessories: false,
      optionProduct: "",
      optionCommand: [],
      productNumber: 1,
      packagingList: [],
      hover: false,
    };
  },
  methods: {
    slugify: function(text) {
      return text
        .toString()                     // Cast to string
        .toLowerCase()                  // Convert the string to lowercase letters
        .normalize('NFD')       // The normalize() method returns the Unicode Normalization Form of a given string.
        .trim()                         // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    },
    order_resume: function() {
      var element = document.getElementById("shop");
      var scrollElement = element.offsetTop;
      window.scrollTo({
        top: scrollElement,
        behavior: "smooth"
      });
    },
    scrollToShoppingCart () {
      vueScrollTo.scrollTo('#shopping-cart', {
        offset: -100
      })
    },
    detail: function(product) {
      this.$emit("detail-product", product);
    },
    selectDrive () {
      this.$emit('select-drive', this.selectedCityDrive)
    },
    //scoll toward recapOrder Component
    scrollOrder: function() {
      Bus.$emit("scroll-order-recap");
    },
    //envoie du produit sélectionner
    selectProduct (product) {
      if (localStorage.getItem("previousProduct")) {
        localStorage.removeItem("previousProduct")
      }
      product.selected = !product.selected
      if (this.categorySelected !== 'pechavyHouse') {
        for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].id === product.id) {
            this.products[i].selected = true
          } else {
            this.products[i].selected = false
          }
        }
        this.product = product
        localStorage.setItem('command', JSON.stringify(this.command))
        localStorage.setItem('product', JSON.stringify(this.product))
        if (localStorage.getItem('orderAborted') === null && this.customerType !== "pro") {
          $('#step-content-2').collapse('show')
        }
      }
      this.getCity()
      this.$emit('product', this.product, this.customerType)
    },
    selectPackaging (packaging) {
      this.packaging = packaging
      localStorage.setItem('command', JSON.stringify(this.command))
      localStorage.setItem('packaging', JSON.stringify(this.packaging))
      if (localStorage.getItem('orderAborted') === null) {
        $('#step-content-3').collapse('show')
      }
      this.$emit('package-selected', this.packaging)
    },
    //choix de la quantité
    quantity_selected: function(temp_number) {
      this.$emit("quantity-selected", temp_number);
    },
    //choix des options
    option_selected: function(option) {
      this.optionProduct = option;
      option.selected = !option.selected;
      if (option.selected == true) {
        if (this.optionCommand.length !== 0) {
          for (var i = 0; i < this.optionCommand.length; i++) {
            if (option.id == this.optionCommand[i].id) {
              break;
            } else if (
              option.id !== this.optionCommand[i].id &&
              i == this.optionCommand.length - 1
            ) {
              this.optionCommand.push(option);
            }
          }
        } else {
          this.optionCommand.push(option);
        }
      } else {
        this.optionCommand = this.optionCommand.filter(t => {
          return t.id !== option.id;
        });
      }
      localStorage.setItem("packaging", JSON.stringify(this.option));
      this.$emit("option-selected", this.optionCommand);
    },
    //choix du mode de livraison
    selectDelivery (deliveryMode) {
      this.delivery_mode = deliveryMode
      this.$emit('delivery-choosen', this.delivery_mode)
      if (deliveryMode === 'drive') {
        vueScrollTo.scrollTo('#drive', {
          offset: -100
        })
      }
      if (deliveryMode === 'home') {
        vueScrollTo.scrollTo('#home', {
          offset: -100
        })
      }
      if (this.delivery_mode === 'home' && this.auth === true) {
        for (let i = 0; i < this.city.length; i++) {
          if (this.city[i].zipcode == this.user.zipcode) {
            this.selectedCity = this.city[i]
            break
          }
        }
        this.$emit('get-delivery', this.selectedCity, this.delivery_mode)
        return this.selectedCity
      }
      if (localStorage.getItem('orderAborted') === null && this.categorySelected !== 'pechavyHouse') {
        // Proceder au paiment
      }
    },
    selectAccessory (accessory) {
      this.selectedAccessory = accessory
      //console.log(accessory)
      accessory.selected = !accessory.selected
      if (accessory.selected) {
        if (this.selectedAccessories.length !== 0) {
          for (var i = 0; i < this.selectedAccessories.length; i++) {
            if (accessory.id == this.selectedAccessories[i].id) {
              break;
            } else if (
              accessory.id !== this.selectedAccessories[i].id &&
              i == this.selectedAccessories.length - 1
            ) {
              this.selectedAccessories.push(accessory);
            }
          }
        } else {
          this.selectedAccessories.push(accessory);
        }
      } else {
        this.selectedAccessories = this.selectedAccessories.filter(t => {
          return t.id !== accessory.id;
        });
      }

      this.$emit("accessory-selected", this.selectedAccessories);
      if (this.categorySelected !== "pechavyHouse" && localStorage.getItem("orderAborted") == null) {
        // ouvrir step 4
      }
    },
    showAllAccessories () {
      this.allAccessories = true
    },
    hideAllAccessories () {
      this.emptyAccessories = true
      this.selectedAccesories = []
      this.$emit('accessory-selected', this.selectedAccessories)
      $('#step-content-4').collapse('show')
    },
    get_search: function(city) {
      this.searchCity = city;
    },
    selectCity (result) {
      this.cityMessage = result.name + " " + "(" + result.zipcode + ")";
      this.selectedCity = result;
      this.$emit("get-delivery", this.selectedCity, this.delivery);
    },
    delivery_check: _.debounce(function(city) {
      this.searchCity = city;
      if (this.searchCity.length == 0) {
        this.cityMessage = "";
      } else {
        for (var i = 0; i < this.city.length; i++) {
          if (
            this.searchCity == this.city[i].name.toLowerCase() ||
            this.searchCity == this.city[i].zipcode
          ) {
            this.cityMessage =
              this.city[i].name + " " + "(" + this.city[i].zipcode + ")";
            break;
          }
        }
      }
    }, 200),
    async getCity () {
      //Quand on change de produit, permet d'obtenir toutes les villes
      this.city = []
      const cityUrl = this.product.category === 4 ? '/simple-product/rest/distance-log-list/' : '/simple-product/rest/distance-granule-list/'
      const response = await axios({
        method: 'get',
        url: cityUrl
      })
      if (this.product.category === 4) {
        for (let i = 0; i < response.data.length; i++) {
          for (let j = 0; j < this.logCity.length; j++) {
            if (response.data[i].zipcode === this.logCity[j]) {
              this.city.push(response.data[i])
            }
          }
        }
      } else {
        this.city = response.data
      }
    }
  },
  computed: {
    accessories () {
      let products = []
      if (this.categorySelected === 'logs') {
        products = this.products.filter((product) => {
          return product.category === 1 || product.category === 7
        })
      } else if (this.categorySelected === 'granules') {
        products = this.products.filter((product) => {
          return product.category === 7
        })
      }
      return this.allAccessories ? products : products.slice(0, 3)
    },
    order_Mpechavy: function() {
      if (this.categorySelected == "pechavyHouse") {
        return _.orderBy(this.products, "order");
      }
    },
    step_order: function() {
      if (this.categorySelected !== "pechavyHouse") {
        var product = this.product;
        var packaging = this.packaging;
        var delivery = this.delivery;
        var step_list = [product, packaging, delivery];
        var step_number = 0;
        for (var i = 0; i < step_list.length; i++) {
          if (step_list[i] == "") {
            step_number += 1;
          }
        }
      } else {
        var step_number = 0;
        var accessories = this.selectedAccessory;
        var delivery = this.delivery;
        var step_list = [accessories, delivery];
        for (var i = 0; i < step_list.length; i++) {
          if (step_list[i] == "") {
            step_number += 1;
          }
        }
      }
      return step_number;
    },
    product_length: function() {
      if (this.categorySelected == "pechavyHouse") {
        return this.selectedAccessories.length;
      }
    },
    calcul_city: function() {
      //Quand on charge la page, lance le calcul de la ville
      if (
        this.auth == true &&
        this.city.length > 0 &&
        this.categorySelected !== "pechavyHouse"
      ) {
        //Utilisateur connecté, on vérifie que sa ville et dans la liste des ville
        for (var i = 0; i < this.city.length; i++) {
          if (this.city[i].zipcode == this.user.zipcode) {
            //Uncomment this line to check city name
            //if (this.slugify(this.city[i].name) == this.slugify(this.user.city)) {
              this.selectedCity = this.city[i];
              this.checkCityDelivery = true;
              break;
            //}
          } else {
            this.checkCityDelivery = false;
          }
        }
        this.$emit(
          "get-delivery",
          this.selectedCity,
          this.delivery,
          this.checkCityDelivery
        );
        return this.selectedCity;
      }
    }
  }
};
</script>
