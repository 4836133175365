<template src="../../templates/sidebar.html" />
<script>
export default {
  name:'sidebar',
  methods: {
    hideSidebar () {
      this.$emit('order-modal-closed')
    }
  }
}
</script>

