var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "modal-payment",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "modal-payment-label",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("p", { staticClass: "text-primary font-weight-bold" }, [
              _vm._v(
                "\n          vous allez être redirigé sur notre site partenaire afin de procéder au paiement de votre commande.\n        "
              )
            ]),
            _vm._v(" "),
            _vm.discount
              ? _c("p", { staticClass: "text-primary font-weight-bold" }, [
                  _vm._v(
                    "\n            Vous avez réalisé plus de 10 commandes, nous vous offrirons 6 sacs supplémentaires lors du retrait de votre achat.\n        "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "form",
              {
                staticClass: "flex-container",
                attrs: { method: "POST", action: _vm.paybox.action }
              },
              [
                _c("div", { staticClass: "form-h" }, [
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_SITE" },
                    domProps: { value: _vm.paybox.pbx_site }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_RANG" },
                    domProps: { value: _vm.paybox.pbx_rang }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_IDENTIFIANT" },
                    domProps: { value: _vm.paybox.pbx_identifiant }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_TOTAL" },
                    domProps: { value: _vm.paybox.pbx_total }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_DEVISE" },
                    domProps: { value: _vm.paybox.pbx_devise }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_CMD" },
                    domProps: { value: _vm.paybox.pbx_cmd }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_TIME" },
                    domProps: { value: _vm.paybox.pbx_time }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_BILLING" },
                    domProps: { value: _vm.paybox.pbx_billing }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_SHOPPINGCART" },
                    domProps: { value: _vm.paybox.pbx_shoppingcart }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_PORTEUR" },
                    domProps: { value: _vm.paybox.pbx_porteur }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_RETOUR" },
                    domProps: { value: _vm.paybox.pbx_retour }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_EFFECTUE" },
                    domProps: { value: _vm.paybox.pbx_effectue }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_ANNULE" },
                    domProps: { value: _vm.paybox.pbx_annule }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_REFUSE" },
                    domProps: { value: _vm.paybox.pbx_refuse }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_REPONDRE_A" },
                    domProps: { value: _vm.paybox.pbx_repondre_a }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_HASH" },
                    domProps: { value: _vm.paybox.pbx_hash }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_AUTOSEULE" },
                    domProps: { value: _vm.paybox.pbx_autoseule }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_LANGUE" },
                    domProps: { value: _vm.paybox.pbx_langue }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: "PBX_HMAC" },
                    domProps: { value: _vm.paybox.pbx_hmac }
                  })
                ]),
                _vm._v(" "),
                _vm.path
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { id: "submit-lp", type: "submit" }
                      },
                      [_vm._v("\n            Confirmer\n          ")]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { id: "submit-origin", type: "submit" }
                      },
                      [_vm._v("\n            Confirmer\n          ")]
                    )
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "modal-payment-label" } },
        [_vm._v("\n            Confirmation de paiement\n        ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }