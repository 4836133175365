<template src ="../../templates/shoppingCart.html" />
<script>
import Bus from '../Bus.js'
import _ from 'lodash'
import axios from 'axios'
import { stringify } from 'querystring'
import orderStep from './orderStep.vue'
export default {
  name: 'shoppingCart',
  props: [
    "paymentLauncher",
    "checkCity",
    "itemLimit",
    "itemQuantity",
    "productSelected",
    "categorySelected",
    "command",
    "packageSelected",
    "distance",
    "deliveryGrid",
    "orders",
    "auth",
    "user"
  ],
  mixins: [ orderStep ],
  filters: {
    comma: function(value) {
      return value.toLocaleString();
    }
  },
  created() {
    const referrers = [
      'http://localhost:8000/granules-vrac/',
      'https://www.pechavy-energie-bois.fr/granules-vrac/'
    ]
    if (referrers.includes(document.referrer)) {
      this.customerType = 'pro'
    }
    if (window.location.href.includes('?type=vrac')) {
      this.customerType = 'pro'
    }
  },
  updated() {
    this.get_delivery_price;
    if (this.paymentLauncher == true) {
      if (this.payboxCall == false) {
        this.payment();
      }
    }
    if (
      this.command.product.category == 5 &&
      this.packageSelected == "sac" &&
      this.command.quantity > 59
    ) {
      this.command.quantity = 59;
    }

    //Update stere gift algorithm
    if (this.command.product.category == 4 &&
      this.packageSelected == "palett" &&
      this.command.quantity >= 4
    ) {
      this.gift_command = true;
      this.gift_matches = true;
      //this.gift_apron = true;
    }

    if (this.command.product.category == 4 &&
      this.packageSelected == "palett" &&
      this.command.quantity >= 2 &&
      this.command.quantity < 4
    ) {
      this.gift_command = false;
      this.gift_matches = false;
      this.gift_apron = false;
      //console.log("Allumettes offertes")
    }
    if (
      this.command.product.category == 4 &&
      this.packageSelected == "palett" &&
      this.command.quantity < 2
    )
    {
      this.gift_command = false;
      this.gift_matches = false;
      this.gift_apron = false;
    }

    this.commandProduct.name =
      this.command.product.product_title + " " + this.command.product.title;
    if (this.auth == true) {
      this.command.id = this.orders.length + 1;
    }
    Bus.$on("customer-select", type => {
      this.customerType = type;
    });
  },
  data() {
    return {
      suppCity: [
        "47130",
        "47220",
        "47270",
        "47340",
        "47360",
        "47470",
        "47600",
        "32700"
      ],
      adress: "",
      zipcode: 0,
      citySelect: "",
      checked: false,
      gift_command: false,
      gift_matches: false,
      gift_apron: false,
      gift: false,
      cgvError: false,
      reduc: 0,
      distanceTop: 0,
      payboxCall: false,
      driveCity: "",
      loader: false,
      message: "",
      phone: "",
      productError: false,
      deliveryPackagingError: false,
      packagingError: false,
      granuleDriveError: false,
      accessoriesDriveError: false,
      logDriveError: false,
      deliveryError: false,
      volume: "",
      city_input: "",
      zipcode_input: "",
      authError: false,
      trajet: 0,
      socialname: "",
      customerType: "particular",
      weight: 0,
      scrolled: false,
      initialShopPosition: "",
      tva2: "",
      commandProduct: {}
    };
  },
  methods: {
    connexion: function() {
      this.$emit("connexion");
    },
    creation: function() {
      this.$emit("creation");
    },
    quantity: function() {
      localStorage.setItem("command", JSON.stringify(this.command));
      /* this.$emit("quantity-storage-update"); */
    },
    // check le nombre d'item sélectionner si = 0 supprimer l'item dans la commande
    check_number: function(accessory) {
      if (accessory.temp_number == 0) {
        this.command.accessories = this.command.accessories.filter(item => {
          return item.id !== accessory.id;
        });
        Bus.$emit("update-product");
      }
      var commandStorage = this.command;
      localStorage.setItem("command", JSON.stringify(commandStorage));
    },
    doPayment () {
      this.payboxCall = true;
      if (!this.auth) {
        $('#modal-signin').modal('show')
      }
      else if (this.categorySelected !== "pechavyHouse") {
        // Vérifie si la commande est complète puis l'envoie au server afin de la crée
        this.auth == false ? (this.authError = true) : (this.authError = false);
        this.checked == false
          ? (this.cgvError = true)
          : (this.cgvError = false);
          this.delivery_packaging_error == true ? this.deliveryPackagingError = true: this.deliveryPackagingError = false;
          this.granule_drive_error == true ? this.granuleDriveError = true : this.granuleDriveError = false;
          this.log_drive_error == true ? this.logDriveError = true : this.logDriveError = false;
          this.pechavy_drive_error !== false ? this.accessoriesDriveError = true: this.accessoriesDriveError = false;
        this.command.delivery.type == ""
          ? (this.deliveryError = true)
          : (this.deliveryError = false);
        this.productSelected == ""
          ? (this.productError = true)
          : (this.productError = false);
        this.packageSelected == ""
          ? (this.packagingError = true)
          : (this.packagingError = false);
        if (
          this.accessoriesDriveError == false &&
          this.log_drive_error == false &&
          this.granuleDriveError == false &&
          this.checkCity == true &&
          this.authError == false &&
          this.cgvError == false &&
          this.deliveryPackagingError == false &&
          this.packagingError == false &&
          this.deliveryError == false &&
          this.productError == false
        ) {
          axios({
            url: "/paiement/create_order/",
            method: "post",
            data: {
              cgv: this.checked,
              order: this.command,
              categorySelected: this.categorySelected,
              package: this.packageSelected,
              gift_command: this.gift_command
            },
            onUploadProgress: () => {
              this.loader = true;
            }
          })
            .then(response => {
              //var paybox = response.data;
              var paybox = response.data.info;
              this.$emit("form-paybox", paybox);
              var discount = response.data.discount;
              this.$emit("form-discount", discount);
              localStorage.removeItem("command");
              this.loader = false;
            })
            .catch(error => {
              this.loader = false;
            });
        }
      } else if (this.categorySelected == "pechavyHouse") {
        // Vérifie si la commande est complète puis l'envoie au server afin de la crée
        this.payboxCall = true;
        this.auth == false ? (this.authError = true) : (this.authError = false);
        this.checked == false
          ? (this.cgvError = true)
          : (this.cgvError = false);
        this.command.delivery.type == ""
          ? (this.deliveryError = true)
          : (this.deliveryError = false);
        this.command.accessories.length > 0
          ? (this.productError = false)
          : (this.productError = true);
        if (
          this.authError == false &&
          this.cgvError == false &&
          this.deliveryError == false &&
          this.productError == false
        ) {
          axios({
            url: "/paiement/create_order/",
            method: "post",
            data: {
              cgv: this.checked,
              order: this.command,
              categorySelected: this.categorySelected,
              package: this.packageSelected
            },
            onUploadProgress: () => {
              this.loader = true;
            }
          })
            .then(response => {
              var paybox = response.data;
              this.$emit("form-paybox", paybox);
              localStorage.removeItem("command");
              this.loader = false;
            })
            .catch(error => {
              this.loader = false;
            });
        }
      }
    },
    devis: function() {
      axios({
        url: "/paiement/send-quotation/",
        method: "post",
        data: {
          order: this.command,
          phone: this.phone,
          name: this.socialname,
          email: this.email,
          zipcode: this.zipcode_input,
          city: this.city_input,
          volume: this.volume
        },
        onUploadProgress: () => {
          this.loader = true;
        }
      })
        .then(response => {
          this.loader = false;
          this.message = "votre demande a bien été effectué";
        })
        .catch(error => {
          this.loader = false;
          this.message = "Une erreur est survenue...";
        });
    }
  },
  computed: {
    price_gift_limit: function() {
      var total = parseInt(this.command.quantity) + this.itemQuantity;
      if (this.command.product.category == 2) { //Ce produit est category Granules de sac
        if (
          total > this.itemLimit &&
          this.itemLimit !== 0 &&
          this.command.product.is_discount == false &&
          this.command.packageStorage == "sac"
        ) {
          //
          this.gift = true;
          return true;
        } else {
          this.gift = false;
          return false;
        }
      } else if ( //Plus de 4 Sacs bois bûche NF30cm
        this.command.product.id == 21 &&
        this.command.quantity >= 4 &&
        this.command.packageStorage == "sac"
      ) {
        this.gift = true;
        return true;
      } else {
        this.gift = false;
        return false;
      }
    },
    email_error: function() {
      if (this.command.email.length > 0) {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (!reg.test(this.command.email)) {
          return "Cette adresse email ne possède pas un format valide !";
        } else {
          return "";
        }
      }
      return "";
    },
    granule_drive_error: function() {
      if((this.command.product.id == 2) || (this.command.product.id == 5)) {
        if(this.command.delivery.type == 'drive' && this.command.cityDrive == 'bordeaux') {
          return true;
        }
        }else {
          return false
      }
    },
    log_drive_error: function() {
      if((this.command.product.category == 4)) {
        if(this.command.delivery.type == 'drive' && this.command.cityDrive == 'bordeaux') {
          return true;
        }
        else {
          return false
      }
    } else {
          return false
    }
    },
    pechavy_drive_error: function() {
       this.accessoriesProductError = []
       var error = false
      if(this.command.delivery.type == 'drive' && this.command.cityDrive == 'bordeaux') {
        for(var i = 0; i< this.command.accessories.length; i++) {
          if(this.command.accessories[i].category == 7) {
            //console.log('erracc',this.command.accessories[i].title, ':',this.accessoriesProductError.indexOf(this.command.accessories[i].title))
            error = true
            if(this.accessoriesProductError.indexOf(this.command.accessories[i].title) == -1){
            this.accessoriesProductError.push(this.command.accessories[i].title)
            }
          }
        }
          if(error == true) {
            return this.accessoriesProductError
          }else if (error == false) {
            return false
          }
        }else {
          return false
      }
    },
    phone_error: function() {
      if (this.phone.length > 0) {
        var reg = /^((\+)33|0)[1-9](\d{2}){4}$/;
        if (!reg.test(this.phone)) {
          return "Numéro de téléphone non valide";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    volume_error: function() {
      if (this.volume.length > 0) {
        if(this.volume<3) {
          return "Les commandes de vrac démarrent à 3 tonnes";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    delivery_packaging_error: function() {
      if (this.packageSelected !== 'palett' && this.command.delivery.type !== 'drive') {
        return true
      }else {
        return false
      }
    },
    delivery_adress: function() {
      if (this.auth == true) {
        this.adress = this.user.adress1;
        this.zipcode = this.user.zipcode;
        this.city = this.user.city;
      }
      return this.city + "(" + this.zipcode + ")";
    },
    total_price: function() {
      var total = 0;
      //if (this.command.product.title == "NF 50cm") {
      //  console.log(this.command.product.half_palett)
      //  console.log(this.command.product)
      //  if (this.packageSelected == "sac") {
      //    this.command.product.price = this.command.product.price ;
      //  } else if (this.packageSelected == "palett") {
      //    this.command.product.price = "44.50";
      //  }
      //} else if (this.command.product.title == "NF 30cm") {
      //  if (this.packageSelected == "sac") {
      //    this.command.product.price = this.command.product.price;
      //  } else if (this.packageSelected == "palett") {
      //    this.command.product.price = this.command.product.price_tonne;
      //  }
        /*       } else if(this.command.product.id == 21) {
          if(this.command.quantity >= 5) {

          } */
      //} else if (this.command.product.title == "Pack 5 bûches") {
      if (this.command.product.title == "Pack 5 bûches") {
        if (this.packageSelected == "half-palett") {
          this.command.product.price = this.command.product.half_palett;
        } else if (this.packageSelected == "palett") {
          if (this.command.delivery.type == "drive") {
            // this.command.product.price = "308";
            this.command.product.price = this.command.product.price_tonne_pallet;
          } else {
            this.command.product.price = this.command.product.price_tonne_pallet;
            /* this.command.quantity > 90
              ? (this.command.product.price = "438")
              : this.command.quantity > 60
              ? (this.command.product.price = "408")
              : this.command.quantity > 30
              ? (this.command.product.price = "378")
              : (this.command.product.price = "358"); */
          }
        } else {
          this.command.product.price = this.command.product.price
          /* this.command.quantity > 39
            ? (this.command.product.price = "2.60")
            : this.command.quantity > 19
            ? (this.command.product.price = "2.80")
            : (this.command.product.price = "3.50"); */
        }
      }
      for (var i = 0; i < this.command.accessories.length; i++) {
        total +=
          this.command.accessories[i].price *
          this.command.accessories[i].temp_number;
      }
      for (var t = 0; t < this.command.options.length; t++) {
        total += this.command.options[t].price * this.command.quantity;
      }
      if (this.command.packageStorage == "sac") {
        if (this.command.product.category == 2) {
          //var pdb = (this.command.product.price_tonne/1000) * this.command.product.weight;
          total += this.command.product.price * this.command.quantity * 6;
          // total = Math.round(total * 100) / 100;
        } else {
          total += this.command.product.price * this.command.quantity;
          total = Math.round(total * 100) / 100;
        }
      }
      else if (this.command.packageStorage == "palett") // Commande de sac en palette
      {
        if (this.command.product.id == 21)
        {
          total += 5 * this.command.quantity;
        }
        else if (this.command.product.category !== 4)
        {
          total += this.command.product.price_tonne_pallet * this.command.quantity;
        }
        else
        {
          total += this.command.product.price_tonne * this.command.quantity;
        }
      }
      else if (this.command.packageStorage == "half-palett")
      {
        if (this.productSelected.id !== 2) {
          this.halfPackageBag = 33;
        } else if (this.productSelected.id == 2) {
          this.halfPackageBag = 28;
        }
        total += this.command.product.half_palett * this.command.quantity;
      }
      return total.toFixed(2);
    },
    final_price: function() {
      var price = 0;
      if (this.total_price > 0) {
        var price =
          parseFloat(this.total_price) + parseFloat(this.get_delivery_price);
        if ( //Granulés sac
          this.command.product.category == 2 &&
          this.command.packageStorage == "sac" &&
          this.command.product.is_discount == false
        ) {
          if (this.price_gift_limit == true) { //on offre un sac en déduisant le prix du sac
            //Enlever les commentaire pour ajouter la réduction des 10 sacs dès la commande
            //this.reduc = this.command.product.price * 6;
            //price -= this.reduc;
          }
        }
        //else if ( //bois bûche nf30cm
        //  this.command.packageStorage == "sac" &&
        //  this.command.product.id == 21
        //) {
        //  if (this.price_gift_limit == true) { //on offre un sac en déduisant le prix du sac
        //    this.reduc = this.command.product.price;
        //    price -= this.reduc;
        //  }
        //}
        this.command.total = price;
      } else {
        price = 0;
      }
      price = Math.round(price * 100) / 100;
      return price;
    },
    get_delivery_price: function() {
      var delivery = 0;
      if (this.command.delivery.type == "drive") {
        return 0;
      } else if (
        // calcule le prix de livraison pour le bois nf30 et nf50
        (this.command.product.title == "NF 30cm" ||
          this.command.product.title == "NF 50cm") &&
        this.command.delivery.type == "home"
      ) {
        if (this.command.quantity == 1) {
          delivery = 65;
        } else if (this.command.quantity == 2) {
          delivery = 80;
        } else if (this.command.quantity == 3) {
          delivery = 90;
        } else if (this.command.quantity > 3) {
          delivery = this.command.quantity * 30;
        }
        if (this.auth == true) {
          for (var i = 0; i < this.suppCity.length; i++) {
            if (this.user.zipcode == this.suppCity[i]) {
              // delivery = delivery + 10 * this.command.quantity;
              delivery = delivery;
            }
          }
        } else if (this.auth == false) {
          for (var i = 0; i < this.suppCity.length; i++) {
            if (this.command.delivery.city.zipcode == this.suppCity[i]) {
              // delivery = delivery + 10 * this.command.quantity;
              delivery = delivery;
            }
          }
        }
        return parseInt(delivery);
      } else if (
        this.categorySelected == "pechavyHouse" && // Inclus lesfrais de port à 8 euros pour chaque article
        this.command.delivery.type == "home"
      ) {
        var articleNumber = this.command.accessories.length;
        if (this.total_price >= 30) {
          delivery = 0;
        } else {
          for (var i = 0; i < this.command.accessories.length; i++) {
            delivery += 8 * this.command.accessories[i].temp_number;
          }
        }
        return delivery;
      } else if (
        this.command.product.title == "Pack 5 bûches" && // calcule le prix de livraison pour le pack de 5 buche
        this.packageSelected == "palett" &&
        this.command.delivery.type == "home"
      ) {
        this.calcul_dist <= 30000
          ? (delivery = 44.8)
          : this.calcul_dist > 30000 && this.calcul_dist <= 60000
          ? (delivery = 70.4)
          : this.calcul_dist > 60000 && this.calcul_dist <= 90000
          ? (delivery = 96)
          : this.calcul_dist > 90000
          ? (delivery = 134.4)
          : (delivery = 0);
        delivery = delivery * this.command.quantity;
        return delivery.toFixed(2);
      }
      else if (this.calcul_dist > 0 && this.get_weight > 0)
      // C'est une palette de granulé, on sélectionne la grille
      // en fonction de la distance et du poiods
      // puis on définie le prix final via la formule
      // prix_grille_livraison * (poids/1000)
      {
        for (var i = 0; i < this.deliveryGrid.length; i++) {
          if (
            this.deliveryGrid[i].beginWeight <= this.get_weight &&
            this.deliveryGrid[i].endWeight >= this.get_weight
          ) {
            if (
              this.deliveryGrid[i].beginDistance <= this.calcul_dist &&
              this.deliveryGrid[i].endDistance >= this.calcul_dist
            ) {
              this.command.delivery.price = parseInt(
                this.deliveryGrid[i].price
              );
              //console.log(this.deliveryGrid[i].price)
              //console.log(this.get_weight / 1000)
              var final_price_delivery =
                parseFloat(this.deliveryGrid[i].price) *
                (this.get_weight / 1000);
              //console.log(final_price_delivery)
              return Math.round(final_price_delivery * 100) / 100;
              break;
            }
          }
        }
      } else {
        return 0;
      }
    },
    get_weight: function() {
      this.weight = 0;
      if (this.command.accessories.length > 0) {
        for (var i = 0; i < this.command.accessories.length; i++) {
          this.weight +=
            this.command.accessories[i].weight *
            parseInt(this.command.accessories[i].temp_number);
        }
      }
      if (this.packageSelected == "sac") {
        this.weight += this.command.product.weight * this.command.quantity;
      } else if (this.packageSelected == "palett") {
        this.weight +=
          this.command.product.weight *
          this.command.product.sac *
          this.command.quantity;
      } else if (this.packageSelected == "half-palett") {
        this.weight +=
          this.command.product.weight *
          this.command.quantity *
          this.halfPackageBag;
      }
      this.command.weight = this.weight;
      return this.weight;
    },
    calcul_dist: function() {
      if (this.command.delivery.type == "home") {
        return this.command.delivery.city.distance;
      } else {
        return 0;
      }
    }
  }
};
</script>
