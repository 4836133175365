// Import bootstrap js file (include jquery and popper.js)
import './bootstrap'
import { fixedTop, scrollTo } from './functions'
import Vue from 'vue'
import Connect from './components/connect.vue'
import Home from './components/home.vue'
import Cookies from 'js-cookie'

// app sass files
import '../scss/app.scss'

$(function() {
  let headerHeight = $('#header').outerHeight()  
  let headerOffset = $('#header').offset().top
  let navigationHeight
  let navigationOffset  
  if ($('#navigation').length) {
    navigationHeight = $('#navigation').outerHeight()
	  navigationOffset = $('#navigation').offset().top
  }
  
  // Init homepage
  $('.btn-shipping, .btn-drive').on('click', (event) => {
    event.preventDefault()
    scrollTo($(event.currentTarget).data('scroll-to'), headerHeight)
  })

  // Init product page
  $('#navigation .nav-link, .btn-advices').on('click', (event) => {
    event.preventDefault()
    scrollTo($(event.currentTarget).data('scroll-to'), headerHeight + navigationHeight)
  })

  $('.btn-characteristics').on('click', (event) => {
    event.preventDefault()
    const showId = $(event.currentTarget).data('show-id')
    $(showId).toggleClass('d-none')
  })

  $('[data-customer-type]').on('click', (event) => {
    event.preventDefault()    
    localStorage.setItem('customerType', $(event.currentTarget).data('customer-type'))
    window.location.replace($(event.currentTarget).attr('href'))
  })
  // Init all events  
  window.onscroll = () => {
    fixedTop($('#header'), headerOffset)
    if ($('#navigation').length) {
      fixedTop($('#navigation'), navigationOffset - headerOffset)
    }
  }

  // Init flash alert
  const showAlert = Cookies.get('alert')
  if (typeof showAlert !== 'undefined' && showAlert === 'hide') {
    $('.alert-flash').removeClass('show')
    $('.alert-flash').addClass('d-none')
  }
  $('.alert-flash').on('closed.bs.alert', function () {
		headerHeight = $('#header').outerHeight()
    headerOffset = $('#header').offset().top
    if ($('#navigation').length) {
      navigationHeight = $('#navigation').outerHeight()
      navigationOffset = $('#navigation').offset().top
    }
    Cookies.set('alert', 'hide')
  })

  $('#nav-toggler').on('click', (event) => {
    event.preventDefault()
    $(event.currentTarget).toggleClass('open')
    $('#nav-toggler-close').toggleClass('d-none')
    $('#nav-toggler-open').toggleClass('d-none')
    $('#navbar').toggleClass('show')
    $('#menu').toggleClass('collapse')
    $('#menu').toggleClass('navbar-collapse')
    $('#appconnect').toggleClass('d-none')
    $('.btn-cart').toggleClass('btn-primary')
    $('.btn-cart').toggleClass('btn-secondary')
    if ($('#alert').length) {
      $('#alert').alert('close')
    }
    if ($('#navbar').hasClass('show')) {
      $(document.body).css('overflow', 'hidden')
    } else {
      $(document.body).css('overflow', 'auto')
    }
  })
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.filter('levelUp', function(value) {
  if(!value) return ''
  return Math.round(value)
})
Vue.filter('roundPrice', function(value) {
  if (!value) return '';
  return Math.round(value*100)/100
})
Vue.filter('orderBy', function(value) {
  value.sort(function(a, b) {
    return a.id - b.id;
  })
})
Vue.filter('intPrice', function(value) {
  if (!value) return '';
  return parseInt(value)
})
Vue.filter('half', value =>{
  if(!value) return '';
  return Math.ceil(value/2)
})
Vue.filter('title', function(value) {
  if(!value) return ''
  return value.charAt(0).toUpperCase() + value.substring(1).toLowerCase()
})
Vue.filter('upper', function(value) {
  if(!value) return '';
  return value.toUpperCase()
})
Vue.filter('lower', function(value) {
  if(!value) return '';
  return value.toLowerCase()
})

// init vue component
if($('#myapp').length) {
  let vm = new Vue({
    el: '#myapp',
    components: {
      Home
    }
  })
  global.vm = vm
}
if($('#appconnect').length){
  let vm = new Vue({
    el: '#appconnect',
    components: {
      Connect
    }    
  })
  global.vm = vm
}
