
<template src="../../templates/autocomplete.html"> 
     
</template> 
<script>
import lodash from 'lodash'
// import css from '../../css/autocomplete.css' 
export default { 
  name: "auto-complete", 
  props: ["items"], 
  data() { 
    return { 
      search: "", 
      results: '', 
      arrowCounter: -1, 
      isOpen: false 
    }; 
  },

  mounted() { 
    document.addEventListener('click', this.handleClickOutside); 
  }, 
  destroyed() { 
    document.removeEventListener('click', this.handleClickOutside); 
  }, 
  methods: { 
    handleClickOutside(evt) { 
      if (!this.$el.contains(evt.target)) { 
        this.isOpen = false; 
        this.arrowCounter = -1; 
      } 
    }, 
    onArrowDown() { 
      if (this.arrowCounter < this.results.length) { 
        this.arrowCounter = this.arrowCounter + 1; 
      } 
    }, 
    onArrowUp() { 
      if (this.arrowCounter > 0) { 
        this.arrowCounter = this.arrowCounter - 1; 
      } 
    }, 
      onEnter() { 
        console.log('arrow',this.results[this.arrowCounter])
     // this.search = this.results[this.arrowCounter];
      this.isOpen = false; 
      this.arrowCounter = -1;
      var u = parseInt(this.search)
      var testNumber = isNaN(u)
        if(testNumber == false) {
          for (var i =0; i < this.items.length; i++) {
            if (this.search == this.items[i].zipcode) {
              var result = this.items[i]
              break
              console.log(result)
            }
          }
        } else {
        for (var i =0; i< this.items.length; i++) {
          if (this.search.toUpperCase() == this.items[i].name.toUpperCase()){
            var result = this.items[i]
            break
          }
        }
      }
      this.setResult(result)
    }, 
    setResult(result) {
      this.search = result.name + ''+'(' +result.zipcode + ')'; 
      this.isOpen = false;
      this.$emit("city-selected", result); 
    }, 
    onChange() { 
      this.isOpen = true; 
      this.filterResults(); 
    }, 
    filterResults() { 
      this.results = this.items.filter( 
        item => (item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1) || (item.zipcode.toLowerCase().indexOf(this.search.toLowerCase()) > -1) 
      );
      this.$emit('get-search', this.search)
    },
  }

}; 
</script>