<template src="../../templates/account.html" />
<script>
import axios from 'axios'
export default {
  name: 'account',
  props: ['user'],
  data: () => ({
    adressCorrection: false,
    emailCorrection: false,
    mdpCorrection: false,
    lastname: '',
    validation: false,
    firstname: '',
    city: '',
    zipcode: '',
    email: '',
    adress: '',
    password: '',
    password2:'',
    message: '',
    loading: false
  }),
  computed: {
    valide () {
      return this.email_error === '' && this.password_verification === ''
    },
    email_error () {
      if (this.email.length > 0) {
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
        if (!reg.test(this.email)) {
          return "Cette adresse email ne possède pas un format valide !"
        } else {
          return ""
        }
      }
      return ""
    },
    password_verification () {
      if (this.password2.length > 0) {
        if (this.password !== this.password2) {
          return "les deux mots de passe doivent être identique";
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  },
  methods: {
    account_update () {
      if(!this.adress) {
        this.adress = this.user.adress1;
        this.zipcode = this.user.zipcode;
        this.city = this.user.city;
      }
      if(!this.email) {
        this.email = this.user.email
      }
      axios({
        url:' /authentification/update-registration/',
        method: 'post',
        data: {
          lastname: this.user.last_name,
          firstname: this.user.first_name,
          adress: this.adress,
          zipcode: this.zipcode,
          email: this.email,
          city: this.city,
          password: this.password
        },
        onUploadProgress: () => {
          this.loading = true;
        }
      }).then((response) => {
        this.loading = false;
        if (response.data.createAccountrefused) {
          this.message = 'Un utilisateur utilise déjà cette adresse email'
        } else {
          const userChanged = response.data.user[0]
          this.$emit('update-success', userChanged)
        }
      }).catch(error=> {
        this.loading = false
        console.log('error', error)
      })
    }
  }
}
</script>