<template src = "../../templates/accountCreation.html" />

<script>
import axios from 'axios'
export default {
  name: 'account-creation',
  data: () => ({
    lastname: '',
    firstname: '',
    city: '',
    zipcode: '',
    email: '',
    phone:'',
    rgpd: false,
    adress: '',
    password: '',
    password2:'',
    message: '',
    loading: false
  }),
  methods: {
    signup () {
      if(this.rgpd) {
        this.loading = true
        axios({
          url:'/authentification/registration/',
          method: 'post',
          data: {
            rgpd: this.rgpd,
            lastname: this.lastname,
            firstname: this.firstname,
            adress: this.adress,
            zipcode: this.zipcode,
            email: this.email,
            phone: this.phone,
            city: this.city,
            password: this.password
          }
        }).then((response) => {
          this.loading = false
          if (response.data.createAccountrefused) {
            this.message = 'Un utilisateur utilise déjà cette adresse email'
          } else {
            const user = response.data.user
            this.$emit('creation-success', user)
            $('#modal-signup').modal('hide')
          }
        }).catch(error=> {
          console.log('error', error)
        })
      } else {
        this.message = "Veuillez accepter les conditions RGPD"
      }
    },
    showSigninModal () {
      $('#modal-signup').modal('toggle')
      $('#modal-signin').modal('toggle')
    },
  },
  computed: {
    emailError () {
      if (this.email.length > 0) {
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
        if (!reg.test(this.email)) {
          return "Cette adresse email ne possède pas un format valide !"
        } else {
          return "";
        }
      }
      return "";
    },
    phoneError () {
      if(this.phone.length > 0) {
        const reg = /^((\+)33|0)[1-9](\d{2}){4}$/;
        if (!reg.test(this.phone)) {
          return "Numéro de téléphone non valide"   
        } else {
          return ""
        }
      } else {
        return ""
      }
    },
    passwordVerification () {
      if (this.password2.length > 0) {
        if (this.password !== this.password2) {
          return "les deux mots de passe doivent être identique"
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  }
}
</script>