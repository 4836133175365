<template src="../../templates/connect.html" />
<script>
import axios from 'axios';
import Account from './account.vue'
import Sidebar from './sidebar.vue';
import AccountCreation from './accountCreation.vue';
import AccountConnexion from './accountConnexion.vue';
export default {
  name: 'connect',
  components: {
    Sidebar,
    Account,
    AccountConnexion,
    AccountCreation
  },
  data: () => ({
    hiddenShop: false,
    account: false,
    accountConnexion: false,
    accountCreation: false,
    user: [],
    orderEmpty: false,
    sidebar: false,
    items: [],
    accountMenu: true,
    orderAborted: false,
    auth: false,
    orders: []
  }),
  async created () {
    try {
      const locaction = window.location.pathname.split('/')
      this.hiddenShop = location[1] === 'commande' ? false : true
      const response = await axios({
        method: 'get',
        url:'/authentification/auth_check/'
      })
      this.auth = response.data.auth
      if (this.auth && this.user === '') {
        this.user = response.data.user[0]
        this.orders = response.data.order
        this.items = response.data.item
      }
    } catch (error) {
      console.log(error)
    }
  },
        
        methods: {
            closeOrderAborted: function(){
                localStorage.setItem('orderAborted',JSON.stringify(this.orderAborted = false))
                this.sidebar = false
            },
            showSidebar () {
                localStorage.setItem('orderAborted',JSON.stringify(this.orderAborted = true))
                if (localStorage.getItem('product')) {
                   var product = JSON.parse(localStorage.getItem('product'));
                   if(product.category == 2) {
                       window.location.href='/commande/granules';
                   }else {
                       window.location.href='/commande/logs';
                   }
                } else {
                    this.sidebar = true
                }
            },
            hideSidebar () {
              this.sidebar = false
              this.$emit('order-modal-closed')
            },
            last_order: function() {
                var orderLoader = true
                localStorage.setItem('lastOrder',JSON.stringify (orderLoader))
                for (var i =0; i < this.items.length; i++) {
                    if ((this.items[i].itemCategory == 'Bois bûches') || (this.items[i].itemCategory == 'Bûches compressées')) {
                        window.location.href = "/commande/logs"
                        break
                    } else if(this.items[i].itemCategory == 'Granulés en sac' ) {
                        window.location.href = "/commande/granules"
                        break
                    }
                }

            },
            close(type) {
                if(type == 'account') {
                    this.account = false
                }
                if(type == 'connexion') {
                    this.accountConnexion = false;
                }
                if(type == 'creation') {
                    this.accountCreation = false;
                }
                this.accountMenu= false;
            },
            connexion_success: function(item) {
                this.user = item[0]
                this.auth = true
                this.$emit('user-update', item[0])
            },
            creation_success: function(item) {
                this.user = item[0]
                this.auth = true
            },
            update_success: function(user) {
                this.$emit('user-update', user)
                this.user = user
                this.account = false
            },
            logout: function(){
                axios({
                    url:'/authentification/logout/',
                    method: 'post'
                }).then((response)=> {
                    this.auth = false;
                    this.user = []
                    this.$emit('user-update', this.user)
                }).catch((error)=> {
                    console.log('error')
                })
            }
        }
    }
</script>