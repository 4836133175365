var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cart-header bg-secondary", attrs: { id: "order" } },
    [
      _c("div", { staticClass: "container mt-n4" }, [
        _c("div", { staticClass: "row" }, [
          _vm.path
            ? _c("div", { staticClass: "col-12" }, [
                _c("h2", { staticClass: "h5 text-white text-center" }, [
                  _vm._v(
                    "\n          Commandez maintenant vos granulés de bois sur notre site de Bassens en Gironde.\n        "
                  )
                ])
              ])
            : _c("div", [
                _vm.categorySelected === "granules" &&
                _vm.customerType !== "pro"
                  ? _c("h2", { staticClass: "h5 text-white text-center" }, [
                      _vm._v(
                        "\n          Commander des granulés en sac\n        "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.categorySelected === "granules" &&
                _vm.customerType === "pro"
                  ? _c("h2", { staticClass: "h5 text-white text-center" }, [
                      _vm._v(
                        "\n          Commander des granulés en vrac\n        "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.categorySelected === "logs"
                  ? _c("h2", { staticClass: "h5 text-white text-center" }, [
                      _vm._v("\n          Commander du bois\n        ")
                    ])
                  : _vm._e()
              ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }