const scrollTo = (id, offset) => {
  $([document.documentElement, document.body]).animate({
    scrollTop: $(id).offset().top - offset
  }, 1000)
}

function fixedTop(el, sticky) {
  if ($(window).scrollTop() >= sticky) {
    el.addClass('fixed-top')
  } else {
    el.removeClass('fixed-top')
  }
}

export { fixedTop, scrollTo }