<template src="../../templates/formPayment.html" />
<script>
  export default {
    name:'form-payment',
    props:[
      'paybox',
      'discount'],
    data: () => ({
      path:''
    }),
    mounted () {
      if (window.location.href.includes('commande-lp')) {
          this.path = true
      }
    }
  }
</script>