<template src="../../templates/headerCommand.html" />
<script>
import axios from 'axios'
export default {
  name: 'header-command',
  props: [
    'categorySelected'
  ],
  data: () => ({
    cat: '',
    granules: false,
    wood: false,
    customerType: 'particular',
    navMobile: false,
    typeSelect: false,
    path:''
  }),
  async created () {
    try {
      if (localStorage.getItem('customerType') === 'pro') {
        this.customerType = 'pro'
        this.setCustomerType(this.customerType)
      }
      if (window.location.href.includes('?type=vrac')) {
        this.customerType = 'pro'
        this.setCustomerType(this.customerType)
      }
      const response = await axios({
        method: 'get',
        url: '/category_check/'
      })
      this.cat = response.data.category
      if (this.cat == 'granules') {
        this.granules = true
        this.wood = false
      } else {
        this.wood = true
        this.granules = false
      }
    } catch (error) {
      console.log(error)
    }
  },       
  mounted () {
    if (window.location.href.includes('commande-lp')) {
        this.path = true
    }
  },
  methods: {
    locationPechavy () {
      localStorage.setItem('articlePechavy', true)
      if( location.origin == 'https://www.pechavy-energie-bois.fr') {
        location.replace('https://www.pechavy-energie-bois.fr/bois-buche/')
      } else {
        location.replace('http://localhost:8000/bois-buche/')
      }
    },
    setCustomerType (type) {
      if (type === 'pro') {
        this.typeSelect = true
        this.customerType = 'pro'
      } else if( type === 'particular') {
        this.typeSelect = false;
        this.customerType = 'particular'
      } 
      this.$emit('customer-select', type)
    }
  }
}
</script>
