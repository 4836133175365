<template src="../../templates/accountConnexion.html" />
<script>
import axios from 'axios'
export default {
  name: 'account-connexion',
  data: () => ({
    email: '',
    password: '',
    message: '',
    initPassword: false,
    failedPassword: false,
    loading: false
  }),
  computed: {
    emailError () {
      if (this.email.length > 0) {
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
        if (!reg.test(this.email)) {
          return "Cette adresse email ne possède pas un format valide !"
        } else {
          return ""
        }
      }
      return ""
    }
  },
  methods: {
    signin () {
      if((this.email.length > 0) && (this.emailError === "") && (this.password.length > 0)) {
        this.loading = true
        axios({
          url:'/authentification/connexion/',
          method: 'post',
          data: {
            email: this.email,
            password: this.password
          }
        }).then( (response ) => {
          this.loading = false
          if(response.data.error) {
            this.message = 'L\'identifiant et le mot de passe ne correspondent pas'
          } else {
            const user = response.data.user
            this.$emit('connexion-success', user)
            $('#modal-signin').modal('hide')
          }
        }).catch( (error) => {
          this.loading = false
        })
      }
    },
    showSignupModal () {
      $('#modal-signin').modal('toggle')
      $('#modal-signup').modal('toggle')
    },
    passwordForgotten () {
      if (this.email.length > 0 && this.emailError === "") {
        this.loading = true
        axios({
          url: '/authentification/password-forgotten/',
          method: 'post',
          data: {
            email: this.email
          }
        }).then( (response) => {
          this.loading = false
          if (response.data.successPassword == true) {
            this.initPassword = true
            this.failedPassword = false
          } else {
            this.initPassword = false
            this.failedPassword = true 
          }
        }).catch()
      } else {
        this.message = "Adresse e-mail non valide"
      }
    }
  }      
}
</script>